// @flow

import React from 'react';
import type { Node } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from '@riseart/common';
import { Section, SectionContent, Wrapper } from '@riseart/layout';
import { useStoreCode } from 'shared_services/redux/hooks/useStoreCode';
import { useLocale } from 'shared_services/redux/hooks/useLocale';
import { ArtistListGrid } from 'shared_components/artist/list/Grid';
import { ShortcutLinks } from 'shared_components/common/links/Shortcut';

type Props = {
  cardDisplayStyle?: string,
  title?: string | Node,
  subTitle?: string | Node,
  items: Array<Object>,
  link?: {
    external: boolean,
    target?: string,
    text: string,
    href: string,
  },
  buttons?: Array<{
    external: boolean,
    target?: string,
    text: string,
    href: string,
  }>,
  disableHeadingTags?: boolean,
};

/**
 * infoListRenderer
 *
 * @param {Object} item
 * @returns {Array<any>}
 */
function infoListRenderer(item: Object): Array<any> {
  const infoList = [];

  if (Array.isArray(item.mediums)) {
    infoList.push(
      item.mediums
        .map((medium) => (
          <FormattedMessage id={`filters.artist.medium..${medium}`}>
            {(label: string) => label}
          </FormattedMessage>
        ))
        .join(', '),
    );
  }

  if (item.livesCountry) {
    infoList.push(
      <FormattedMessage id={`countries.${item.livesCountry.toUpperCase()}`}>
        {(country: string) => country}
      </FormattedMessage>,
    );
  }

  return infoList;
}

/**
 * CmsModuleArtistGrid
 *
 * @param {Props} props
 * @returns {Node}
 */
export const CmsModuleArtistGrid = ({
  title = null,
  subTitle = null,
  items,
  link = null,
  buttons,
  disableHeadingTags = false,
}: Props): Node => {
  const storeCode = useStoreCode();
  const locale = useLocale();

  if (!storeCode || !items || !items.length) {
    return null;
  }

  return (
    <Section>
      <Wrapper
        sizes={{
          xs: 'wide',
          sm: 'wide',
          md: 'wide',
          lg: 'narrow',
          xl: 'narrow',
        }}
      >
        <SectionContent
          nodeType="section"
          title={title}
          subTitle={subTitle}
          headingProps={{ tag: disableHeadingTags ? 'span' : 'h2' }}
          headingSubTitleProps={{ tag: disableHeadingTags ? 'span' : 'h3' }}
          link={
            link ? (
              <Link
                to={link.href}
                external={!!link.external}
                {...(link.target ? { target: link.target } : null)}
                title={link.text}
              >
                {link.text}
              </Link>
            ) : null
          }
        >
          <ArtistListGrid
            gridMargin="small"
            items={items}
            storeCode={storeCode}
            locale={locale}
            infoListRenderer={infoListRenderer}
          />
          <ShortcutLinks links={buttons} />
        </SectionContent>
      </Wrapper>
    </Section>
  );
};
